

function gtaProgramFilter() {
    console.log(LOCALIZED);
    this.vue = new Vue({//
        // vue container
        el: '#v-program',
        // Vue data fields

        data: {
            pathways: LOCALIZED.pathways,
            currentPath: 0 ,
            currentCareer: 0 ,
            selectedPath: false,
            selectedCareer: false,
            URL: LOCALIZED['pageURL'],
            displayURL: ""
        },
        computed: {
        },
        watch: {
            currentPath : {
                handler: function(){ 
                    this.setCurrentCareer();
                },
                deep: true,
            },
            currentCareer : {
                handler: function(){ 
                    this.setCurrentCareer();
                },
                deep: true,
            },
        },
        created: function () {
            this.preloadData();
            this.setCurrentCareer();

        },
        // vue methods //
        methods: {
            preloadData: function(){
                if( LOCALIZED.currentPath !== "0" ){
                    for( var i = 0; i < this.pathways.length ; i++){
                        if(this.pathways[i].slug === LOCALIZED.currentPath){
                            this.currentPath = i;
                        }
                    }
                }
                if( LOCALIZED.currentPath !== "0" ){
                    for( var i = 0; i < this.pathways[this.currentPath].careers.length ; i++){
                        if(this.pathways[this.currentPath].careers[i].slug === LOCALIZED.currentCareer){
                            this.currentCareer = i;
                        }
                    }
                }
            },
            setCurrentCareer: function(){
                this.selectedPath = this.pathways[this.currentPath];
                this.selectedCareer = this.selectedPath.careers[this.currentCareer];
                this.setCurrentURL();
            },
            setCurrentURL: function(){
                this.displayURL = this.URL + "?path=" + this.selectedPath.slug + "&career=" + this.selectedCareer.slug;
                history.pushState({}, '', this.displayURL);
            },
            // Function handles opening & closing the dropdown menu.
            toggleDropdown: function (slug) {
               
            },

            
        },
    });
}
