/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                $(function() {
                    // $.scrollify({
                    //     section : ".j-slide",
                    //     interstitialSection:".j-slide--footer"
                    // });
                });

                AOS.init({
                    anchorPlacement: 'top-bottom',
                    once: true,
                });
            },
            finalize: function () {
                // Toggle Menu
                var header = $('.o-header');
                var body = $('body');
                header.on('click', '.j-menu-toggle', function () {
                    header.toggleClass('s-menu-open');
                    body.toggleClass('no-scroll');
                });
                header.find('.menu-slide').on('click',function(e){
                    e.preventDefault();
                    e.stopPropagation();
                    if($(window).innerWidth() < 769 ){
                        $(this).toggleClass('open').parent().toggleClass('open');
                        $(this).siblings('.sub-menu').stop(true, true).slideToggle(200);
                        $(this).siblings('.o-mega-menu').stop(true, true).slideToggle(200);
                    }
                });
                function checkHeaderPosition(){
                    var scroll = $(window).scrollTop();
                    if (scroll >= 200) {
                        header.addClass('o-header__scrolled');
                    } else {
                        if (scroll <= 5) {
                            header.removeClass('o-header__scrolled');
                        }
                    }
                }

                var carousel = $('.j-carousel--images');

                if(carousel.length > 0 ){
                    carousel.each(function(){
                        var _this = $(this);
                        var args = {
                            fade: true,
                            dots: false,
                            arrows: false, 
                            autoplay: true,
                            autoplaySpeed: _this.data('autoplay-speed'),
                            speed: _this.data('speed'),
                            infinite: false,
                        };
                        console.log(args);
                        _this.slick(args);
                    });
                }
                


                $('.j-search--toggle').on('click',function(){
                    var body = $('body');
                    body.toggleClass('search__is-open');
                    if(body.hasClass('search__is-open')){
                        body.addClass('search__is-active');
                        $('.o-search-overlay--form').find('.search-field').focus().select();
                    }else{
                        setTimeout(function(){
                            body.removeClass('search__is-active');
                        }, 600 );
                    }
                });

                checkHeaderPosition();
                //caches a jQuery object containing the header element
                $(window).scroll(function() {
                    checkHeaderPosition();
                });


                window.PageFunctions.callToAction();
                window.PageFunctions.accordion();
                window.PageFunctions.testimonial();
                window.PageFunctions.stepsCarousel();
                window.PageFunctions.smoothScroll();

                window.PageFunctions.shareBlock();
                // 
            }
        },
        // Home page
        'page_template_courses': {
            init: function () {
                // JavaScript to be fired on the home page
                gtaCourseIndexFilter();
            },
            finalize: function () {
            }
        },

        // Home page
        'single_course': {
            init: function () {
                // JavaScript to be fired on the home page
                window.PageFunctions.recommendedCourses();
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // Home page
        'single_program': {
            init: function () {
                gtaProgramFilter();
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

