(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        menu: "",
        menuToggle: "",

        // Smooth scroll on page anchor links.
        // Will scroll to the top for # links.
        
        openFooterCTA : function() {
            $('.o-footer-callout .j-call-to-action .j-slide-button').addClass('slide__open');
            $('.o-footer-callout .j-call-to-action .j-slide-button--slide').delay(150).slideDown(500);
        },
        smoothScroll : function (){
            $('a[href^="#"], body.home .o-header--menu__utility a[href^="/?"]').click(function () {

                if ( $(this).attr('href').includes('#footer-contact-us') ) {
                    PageFunctions.openFooterCTA();   
                }

                if ( $(this).hasClass('o-program-callout--link') ) {
                    if ($(this).attr('href') == '#' || $(this).attr('href') == '#learn') {
                        $(this).attr('href', '#learn');
                        $('.o-program-introduction--call-to-action .j-slide-button').addClass('slide__open');
                        $('.o-program-introduction--call-to-action .j-slide-button--slide').delay(150).slideDown(500);
                    }
                }
                
                console.log('scrolling');
                var scrollTarget;
                // If the link is just # set scrollTarget to top of page
                if($(this).attr('href') === '#'){
                    scrollTarget = 0; //
                }else{ // Otherwise locate the target element and find its offset from the top
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            scrollTarget =  target.offset().top;
                        }
                    }
                }
                // Scroll the page to the target.
                $('html, body').animate({
                    scrollTop: scrollTarget
                }, 1000);
                return false; // Exit.
            });
        },

        testimonial: function(){
            $('.j-testimonials').slick({
                arrows: false,
                dots: true,
            });
        },

        stepsCarousel: function(){
            $('.j-steps').slick({
                arrows: false,
                autoplay: true,
                autoplaySpeed: 5000,
                dots: true,
            });
        },

        
        callToAction: function(){
            $('.j-slide-button').on('click','.j-slide-button--button',function(){
                console.log('clicked');
                $(this).parents('.j-slide-button').toggleClass('slide__open').find('.j-slide-button--slide').delay(150).slideToggle(500);
            });
        },
        accordion: function(){
            $('.j-accordion').on('click','.j-accordion--toggle',function(){
                var _this = $(this);
                // var accordion = _this.parents('.j-accordion');
                var single =  _this.parents('.j-accordion--single');
                single.toggleClass('is__open').find('.j-accordion--body').slideToggle();
                single.siblings('.is__open').removeClass('is__open').find('.j-accordion--body').slideUp();
            });
        },

        recommendedCourses: function(){
            $('.j-recommended-courses').slick({
                arrows: false,
                dots: true,
                slidesToShow: 4,
                centerMode: true,
                centerPadding: '250px',
                infinite: false, 
                responsive: [
                    {
                      breakpoint: 1500,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '250px',
                      }
                    },
                    {
                      breakpoint: 1200,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '150px',
                      }
                    },
                    {
                      breakpoint: 800,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerPadding: '50px',
                      }
                    },
                    {
                      breakpoint: 600,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerPadding: '25px',
                      }
                    },
                ]
            });
        },

        shareBlock : function (){
            var shareBlock = $('.j-share');
            shareBlock.on('click','.j-share--contact', function(){

                PageFunctions.openFooterCTA();  

                var footer = $('#footer-contact-us');
                if (footer.length) {
                     // Scroll the page to the target.
                    $('html, body').animate({
                        scrollTop: footer.offset().top
                    }, 1000);
                }
                return false; // Exit.
            });
            shareBlock.on('click','.j-share--share', function(){
                shareBlock.toggleClass('share_is_open');
            });
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper