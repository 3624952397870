

function gtaCourseIndexFilter() {
    this.vue = new Vue({//
        // vue container
        el: '#v-course-index',
        // Vue data fields

        data: {
            taxonomies: LOCALIZED['taxonomies'],
            courses: LOCALIZED['courses'],
            initialCourses: LOCALIZED['courses'],
            restURL: LOCALIZED['restURL'],
            indexURL: LOCALIZED['indexURL'],
            defaultThumbnail: LOCALIZED['defaultThumbnail'],
            activeDropdown: false,
            mobileDropdown: false,
            page : 1,
            per_page: 12,
            selected: LOCALIZED['onload'],
            request: '',
            isLoading: false,
            sortOpen: false,
            currentSort : LOCALIZED['currentSort'],
            sortOptions: LOCALIZED['sortOptions'],  
        },
        computed: {
        },
        watch: {
            selected : {
                handler: function(){ 
                    this.page = 1;
                    this.fetchResults(); 
                },
                deep: true,
            },
          
            currentSort : function(){ 
                this.page = 1;
                this.fetchResults(); 
            },
        },
        created: function () {
            this.fetchResults();
        },
        // vue methods //
        methods: {

            // Function handles opening & closing the dropdown menu.
            toggleDropdown: function (slug) {
                if(this.activeDropdown === slug){
                    this.activeDropdown = "";
                }else{
                    this.activeDropdown = slug;
                }
            },

            // Resets selection back to empty
            resetAll: function(){
                this.selected =  {
                    'career-cluster' : [],
                    'grade-level' : [],
                    'program' : [],
                    'subject' : [],
                };
            },
            // Checks if there are any selections
            hasSelection: function(){
                var count = this.selected['career-cluster'].length + 
                this.selected['grade-level'].length + 
                this.selected.program.length +
                this.selected.subject.length;
                // Return true if there are any selections
                return (count > 0);
            },

            fetchResults: function(){
                var fetchURL = this.restURL + 'wp/v2/course?_embed&per_page='+ this.per_page +'&page=' + this.page;
                var displayURL = this.indexURL; 
                var prefix = "?";

                this.isLoading = true;

                if(this.selected['career-cluster'].length > 0){
                    fetchURL += "&career-cluster=" + this.selected['career-cluster'].map( function(x){
                        return x.term_id;
                    } ).join(',');
                    
                    displayURL += prefix + "cc=" + this.selected['career-cluster'].map( function(x){
                        return x.term_id;
                    } ).join(',');

                    prefix = "&";

                }

                if(this.selected['grade-level'].length > 0){
                     fetchURL += "&grade-level=" + this.selected['grade-level'].map( function(x){
                        return x.term_id;
                    } ).join(',');
                    
                    displayURL +=  prefix + "gl=" + this.selected['grade-level'].map( function(x){
                        return x.term_id;
                    } ).join(',');

                    prefix = "&";

                }

                if(this.selected['program'].length > 0){
                     fetchURL += "&program=" + this.selected['program'].map( function(x){
                        return x.term_id;
                    } ).join(',');
                    
                    displayURL +=  prefix + "pr=" + this.selected['program'].map( function(x){
                        return x.term_id;
                    } ).join(',');

                    prefix = "&";

                }

                if(this.selected['subject'].length > 0){
                     fetchURL += "&subject=" + this.selected['subject'].map( function(x){
                        return x.term_id;
                    } ).join(',');
                    
                    displayURL +=  prefix + "sj=" + this.selected['subject'].map( function(x){
                        return x.term_id;
                    } ).join(',');

                    prefix = "&";

                }



                if(this.currentSort.order.length > 0 && this.currentSort.orderby.length > 0){
                    fetchURL += "&order=" + this.currentSort.order +  "&orderby=" + this.currentSort.orderby;
                    displayURL += prefix + "order=" + this.currentSort.order +  "&orderby=" + this.currentSort.orderby;
                    prefix = "&";
                }

                

                vueContainer = this;
                history.pushState({}, '', displayURL);

                // ajax request a page
                this.request = fetchURL;
                jQuery.ajax({
                    dataType: 'json',
                    url: fetchURL,
                    success: function(rawJSON, textStatus, request) {
                        var totalPages = request.getResponseHeader('X-WP-TotalPages');

                        if(fetchURL === vueContainer.request){
                            var courses = [];
                            for (i = 0; i < rawJSON.length; i++) { 
                                course = rawJSON[i];

                                var courseObj = {
                                    'link' : course.link,
                                    'post_id' : course.id,
                                    'thumbnail' : "",
                                    'title' : course.title.rendered
                                };
                                if(typeof course._embedded['wp:featuredmedia'] != "undefined" && typeof course._embedded['wp:featuredmedia'][0].media_details != "undefined" ){
                                    courseObj.thumbnail = course._embedded['wp:featuredmedia'][0].media_details.sizes['course-index'].source_url;
                                }else{
                                    courseObj.thumbnail = vueContainer.defaultThumbnail;
                                }
                                courses.push(courseObj);
                            }
                            // If we are grabbing the first page, replace the old results
                            if( vueContainer.page == 1){
                                vueContainer.courses = courses;
                            }else{
                                // otherwise add them on
                                vueContainer.courses = vueContainer.courses.concat(courses);
                            }

                            vueContainer.isLoading = false;

                            // If there are more pages than the page we just fetch then fetch the next page as well
                            if(totalPages > vueContainer.page){
                                vueContainer.page++;
                                vueContainer.fetchResults(false);
                            }else{
                                // Otherwise reset the page to 1;
                                vueContainer.page = 1;
                            }
                        }
                        // get results
                    },
                    error: function(rawJSON) {
                        // otherwise reset page variable to 1
                        vueContainer.page = 1;
                    }
                });
            }

            
        },
    });
}
